#regina_x5F_path,
#jakob_x5F_inner_x5F_path,
#jakob_x5F_outer_x5F_path {
  fill: #fff;
  animation: pulse-effect 1.5s linear infinite;
}

@keyframes pulse-effect {
  0% {
    fill: rgba(255, 255, 255, 1);
  }
  20% {
    fill: rgba(255, 255, 255, 0.7);
  }
  40% {
    fill: rgba(255, 255, 255, 1);
  }
  100% {
    fill: rgba(255, 255, 255, 1);
  }
}
